import { useCarousel } from 'shared/ui';
import { Story } from 'shared/models';
import styles from './styles.module.scss';

interface ReelProps extends Omit<Story, 'order'> {
  index: number;
}

export function Reel({ index, video, id }: ReelProps): JSX.Element {
  const { goToPage } = useCarousel();

  const changePage = (): void => {
    goToPage(index);
  };

  return (
    <div onClick={changePage}>
      <video
        preload='metadata'
        id={`reel-${id}`}
        autoPlay={false}
        className={styles.reel}
        muted
        width={212}
        height={377}
        controls
      >
        <source src={`${video}#t=0.1`} type='video/mp4' />
      </video>
    </div>
  );
}
