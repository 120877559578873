import { useEffect, useState } from 'react';
import { START_TIME_REGISTER_POPUP } from 'shared/constants';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/close_3.svg';
import { getJwt } from 'shared/helpers';
import { useTranslate } from 'shared/hooks';
import { Button } from 'shared/ui';
import styles from './styles.module.scss';

let timeId: NodeJS.Timeout | null = null;

export function ShowRegisterPopup(): JSX.Element | null {
  const jwt = getJwt();
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslate();

  useEffect(() => {
    if (jwt ?? timeId !== null) return;

    timeId = setTimeout(() => {
      setShowPopup(true);
    }, START_TIME_REGISTER_POPUP);
  }, [jwt]);

  const closePopup = (): void => {
    setShowPopup(false);
  };

  if (!showPopup) return null;

  const download = (): void => {
    const downloadEl = document.getElementById('download-app');

    if (!downloadEl) return;

    downloadEl.click();
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_close_icon_container}>
        <CloseIcon onClick={closePopup} />
      </div>
      <div className={styles.container_popup}>
        <h3 className={styles.container_title}>{t('register_and_bonuses')}</h3>
        <div className={styles.container_action_container}>
          <Button
            onClick={download}
            className={styles.container_register}
            title={t('registration2')}
          />
        </div>
      </div>
    </div>
  );
}
