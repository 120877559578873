import { useTranslate } from '../../hooks';
import { AppleDownload } from '../icons';
import styles from './styles.module.scss';

export function AppleDownloadApp(): JSX.Element {
  const { language } = useTranslate();

  return (
    <a
      className={styles.appleStore}
      href='https://apps.apple.com/ru/app/ditl/id1591242197'
      target='_blank'
      referrerPolicy='no-referrer'
      rel='noreferrer'
    >
      <AppleDownload lang={language} />
    </a>
  );
}
