import { useEffect } from 'react';
import { useTranslate } from 'shared/hooks';
import {
  AppleDownloadApp,
  GoogleDownloadApp,
  NashstoreDownloadApp,
  RustoreDownloadApp,
} from 'shared/ui';
import styles from '../register.module.scss';

export function Congruts(): JSX.Element {
  const { t } = useTranslate();

  useEffect(() => {
    const titleEl = document.getElementById('register_title');

    if (!titleEl) return;

    titleEl.style.display = 'none';

    return () => {
      titleEl.style.display = 'initial';
    };
  }, []);

  return (
    <div className={styles.congruts}>
      <p>{t('congruts_text')}</p>
      <div className={styles.congruts_icons}>
        <AppleDownloadApp />
        <GoogleDownloadApp />
        <RustoreDownloadApp />
        <NashstoreDownloadApp />
      </div>
    </div>
  );
}
