import { useTranslate } from '../../hooks';
import { RuDownload } from '../icons';
import styles from './styles.module.scss';

export function RustoreDownloadApp(): JSX.Element | null {
  const { language } = useTranslate();

  if (language !== 'ru') return null;

  return (
    <a
      className={styles.ruStore}
      href='https://apps.rustore.ru/app/com.dnatheteam.ditlandroid'
      target='_blank'
      referrerPolicy='no-referrer'
      rel='noreferrer'
    >
      <RuDownload />
    </a>
  );
}
