import { useTranslate } from '../../hooks';
import { GoogleDownload } from '../icons';
import styles from './styles.module.scss';

export function GoogleDownloadApp(): JSX.Element {
  const { language } = useTranslate();

  return (
    <a
      className={styles.googleStore}
      href='https://play.google.com/store/apps/details?id=com.dnatheteam.ditlandroid'
      target='_blank'
      referrerPolicy='no-referrer'
      rel='noreferrer'
    >
      <GoogleDownload lang={language} />
    </a>
  );
}
