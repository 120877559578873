import { useCarousel } from 'nuka-carousel';
import { ReactComponent as ArrowRight } from 'shared/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from 'shared/assets/icons/arrow-left.svg';
import styles from './styles.module.scss';

export function CustomArrows(): JSX.Element {
  const { currentPage, totalPages, wrapMode, goBack, goForward } = useCarousel();

  const allowWrap = wrapMode !== 'nowrap';

  const enablePrevNavButton = allowWrap || currentPage > 0;
  const enableNextNavButton = allowWrap || currentPage < totalPages - 1;

  return (
    <div className={styles.customArrows}>
      <div className={enablePrevNavButton ? styles.customArrows_prev : undefined} onClick={goBack}>
        <ArrowRight />
      </div>
      <div
        className={enableNextNavButton ? styles.customArrows_next : undefined}
        onClick={goForward}
      >
        <ArrowLeft />
      </div>
    </div>
  );
}
