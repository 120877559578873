import styles from './spinner.module.scss';

interface SpinnerProps {
  className?: string;
}

function Spinner({ className = '' }: SpinnerProps): JSX.Element {
  return <span className={`${styles.loader} ${className}`} />;
}

export { Spinner };
