import { useMemo } from 'react';
import { Accordion, Paper } from 'shared/ui';
import { useTrans, useTranslate } from 'shared/hooks';
import Mobile from 'shared/assets/images/mobile_2.png';
import MobileEn from 'shared/assets/images/mobile_2_en.png';
import styles from './instructions.module.scss';

function Instructions(): JSX.Element {
  const { t, language } = useTranslate();
  const Trans = useTrans();

  const renderImg = useMemo((): JSX.Element => {
    switch (language) {
      case 'ru':
        return <img className={styles.containerMobile} src={Mobile} alt='mobile' />;
      case 'en':
        return <img className={styles.containerMobile} src={MobileEn} alt='mobile' />;
      default:
        return <img className={styles.containerMobile} src={Mobile} alt='mobile' />;
    }
  }, [language]);

  const download = (): void => {
    const downloadEl = document.getElementById('download-app');

    if (!downloadEl) return;

    downloadEl.click();
  };

  return (
    <div className={styles.container}>
      {renderImg}
      <Paper className={styles.containerPaper}>
        <Accordion title={t('how_to_reg')}>
          <Trans i18nKey='how_to_reg_desc'>
            Для регистрации скачай наше
            <strong onClick={download} className={styles.downloadApp}>
              приложение Ditl
            </strong>{' '}
            и установи его на мобильное устройство. Далее нажми на кнопку «Зарегистрироваться» и
            введи свой номер телефона. Теперь вводи код, который пришел на твой номер телефона и
            продолжи регистрацию. После того как будет придуман никнейм и выбраны интересы,
            регистрация будет закончена и можно пользоваться приложением.
          </Trans>
        </Accordion>
        <Accordion title={t('how-to-provide-win-mp')}>{t('how_to_provide_win_mp_desc')}</Accordion>
        <Accordion title={t('how-to-create-cw-mp')}>{t('how_to_create_cw_mp_desc')}</Accordion>
      </Paper>
    </div>
  );
}

export { Instructions };
