import { Story } from '../models';
import { publicApi } from './api';

interface RequireStoriesResult {
  results: Story[];
  next: string | null;
}

export async function requireStories(): Promise<RequireStoriesResult> {
  const res = await publicApi.get<RequireStoriesResult>('/api/stories');
  return res.data;
}
