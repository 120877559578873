import { useEffect, useState } from 'react';
import { Partner as IPartner } from 'shared/models';
import { requirePartners } from 'shared/api';
import { PARTNERS_LIMIT } from 'shared/constants';
import { sortPartners } from '../helpers';

interface UsePartners {
  partners: IPartner[];
  increaseOffset: () => void;
  isLoading: boolean | null;
  isNext: boolean | null;
}

export function usePartners(): UsePartners {
  const [partners, setPartners] = useState<IPartner[]>([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [isNext, setIsNext] = useState<boolean | null>(null);

  useEffect(() => {
    setIsLoading(true);

    requirePartners({ limit: PARTNERS_LIMIT, offset })
      .then((res) => {
        setPartners((prevPartners) => prevPartners.concat(res.results));
        setIsNext(!!res.next);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [offset]);

  const increaseOffset = (): void => {
    setOffset((prevOffset) => prevOffset + PARTNERS_LIMIT);
  };

  return { partners: sortPartners(partners), increaseOffset, isLoading, isNext };
}
