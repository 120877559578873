import { Partner } from 'widgets/partners/partner';
import { ShowMorePartners } from 'features/show-more-partners';
import { Partner as IPartner } from 'shared/models';
import { useTranslate } from 'shared/hooks';
import { usePartners } from './hooks';
import styles from './styles.module.scss';

export function Partners(): JSX.Element | null {
  const { partners, increaseOffset, isLoading, isNext } = usePartners();
  const { t } = useTranslate();

  if (partners.length === 0) return null;

  const renderPartners = (): JSX.Element[] =>
    partners.map((p: IPartner) => (
      <Partner key={p.id} title={p.title} image={p.image} link={p.link} />
    ));

  return (
    <div className={styles.partners}>
      <p className={styles.partners_title}>{t('partners_products')}</p>
      <div className={styles.partners_list}>{renderPartners()}</div>
      {isNext ? <ShowMorePartners increaseOffset={increaseOffset} isLoading={isLoading} /> : null}
    </div>
  );
}
