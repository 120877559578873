import { useEffect, useState } from 'react';
import { Story } from 'shared/models';
import { requireStories } from 'shared/api';
import { sortStories } from '../helpers';

interface UseStories {
  stories: Story[];
  isLoading: boolean | null;
}

export function useStories(): UseStories {
  const [stories, setStories] = useState<Story[]>([]);
  const [isLoading, setIsLoading] = useState<boolean | null>(null);

  useEffect(() => {
    setIsLoading(true);

    requireStories()
      .then((res) => {
        setStories(sortStories(res.results));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!stories.length) return;

    const videos: HTMLVideoElement[] = [];

    stories.forEach((s) => {
      const video = document.getElementById(`reel-${s.id}`);

      if (video) videos.push(video as HTMLVideoElement);
    });

    let currentVideoIndex = 0;

    function playNextVideo(): void {
      if (currentVideoIndex === videos.length - 1) {
        currentVideoIndex = -1;
      }

      if (currentVideoIndex < videos.length - 1) {
        const dot = document.getElementById(`dot-${currentVideoIndex + 1}`);

        if (dot) dot.click();

        currentVideoIndex += 1;
        videos[currentVideoIndex].play();
      }
    }

    videos.forEach((v) => {
      v.addEventListener('ended', playNextVideo);
    });

    videos[currentVideoIndex].play();

    // eslint-disable-next-line consistent-return
    return () => {
      videos.forEach((v) => {
        v.removeEventListener('ended', playNextVideo);
      });
    };
  }, [stories]);

  return { stories, isLoading };
}
