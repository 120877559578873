import { Carousel, CustomArrows, CustomDots } from 'shared/ui';
import { Reel } from './reel';
import { useStories } from './hooks';
import styles from './styles.module.scss';

export function Reels(): JSX.Element | null {
  const { stories } = useStories();

  if (stories.length === 0) return null;

  const renderReels = (): JSX.Element[] =>
    stories.map((s, i) => <Reel video={s.video} key={s.id} id={s.id} index={i} />);

  return (
    <Carousel
      className={styles.reels}
      wrapMode='wrap'
      arrows={<CustomArrows />}
      scrollDistance={232}
      showDots
      showArrows
      dots={<CustomDots />}
      swiping
    >
      {renderReels()}
    </Carousel>
  );
}
