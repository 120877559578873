import { useMemo } from 'react';
import {
  Advertise,
  DownloadAppLinks,
  Footer,
  Header,
  Instructions,
  Partners,
  Reels,
} from 'widgets';
import { ShowRegisterPopup } from 'features/show-register-popup/show-register-popup';
import { DownloadAppPopup } from 'features/download-app-popup';
import { Container } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import Winner from 'shared/assets/images/winner.png';
import Winner2 from 'shared/assets/images/winner_mobile.png';
import Winner2En from 'shared/assets/images/winner_mobile_en.png';
import TabletWinner from 'shared/assets/images/winner_tablet.png';
import WinnerEn from 'shared/assets/images/winner_en.png';
import { ReactComponent as NewLogoIcon } from 'shared/assets/icons/logo_new.svg';
import styles from './main-page.module.scss';

function MainPage(): JSX.Element {
  const { t, language } = useTranslate();

  const renderImg = useMemo((): JSX.Element => {
    switch (language) {
      case 'ru':
        return (
          <>
            <img className={styles.winner} src={Winner} alt='' />
            <img className={styles.winner2} src={Winner2} alt='' />
            <img className={styles.tabletWinner} src={TabletWinner} alt='' />
          </>
        );
      case 'en':
        return (
          <>
            <img className={styles.winner} src={WinnerEn} alt='' />
            <img className={styles.winner2} src={Winner2En} alt='' />
            <img className={styles.tabletWinner} src={WinnerEn} alt='' />
          </>
        );
      default:
        return (
          <>
            <img className={styles.winner} src={Winner} alt='' />
            <img className={styles.winner2} src={Winner2} alt='' />
            <img className={styles.tabletWinner} src={TabletWinner} alt='' />
          </>
        );
    }
  }, [language]);

  return (
    <>
      <div className={styles.imgContainer}>{renderImg}</div>
      <Header />
      <Container>
        <p className={styles.socialNetwork}>{t('social-network')}</p>
        <h1 className={styles.title}>DitL</h1>
        <p className={styles.description}>{t('description-mp')}</p>
        <DownloadAppLinks className={styles.downloadAppLinksUp} />
        <DownloadAppPopup />
        <Reels />
        <div className={styles.newLogoForMobile}>
          <h2>DitL</h2>
          <NewLogoIcon />
          <p>{t('open_yourself_new')}</p>
        </div>
        <h3 className={styles.subTitle}>{t('sub-title-mp')}</h3>
        <p className={styles.ourPurpose}>{t('our-purpose-mp')}</p>
        <Advertise />
        <h3 className={styles.subTitle}>{t('move-premium-feed-mp')}</h3>
        <p className={`${styles.description} ${styles.rag}`}>{t('rag-mp')}</p>
        <p className={`${styles.description} ${styles.rag}`}>{t('rag-desc-mp')}</p>
        <p className={styles.moto}>{t('moto-mp')}</p>
        <Instructions />
        <Partners />
        <NewLogoIcon className={styles.newLogo} />
        <div className={styles.downloadAppLinks}>
          <p className={styles.downloadAppLinksTitle}>{t('download-link-title-mp')}</p>
          <p className={styles.downloadAppLinksTitle}>{t('download-link-title2-mp')}</p>
          <DownloadAppLinks className={styles.downloadAppLinksIcons} />
        </div>
      </Container>
      <Footer />
      <ShowRegisterPopup />
    </>
  );
}

export { MainPage };
