import { ReactPortal, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTrans, useTranslate } from 'shared/hooks';
import WinnerAvatar from 'shared/assets/images/winner_avatar.png';
import { ReactComponent as DownloadAppIcon } from 'shared/assets/icons/download_app.svg';
import { ReactComponent as GetDonatesIcon } from 'shared/assets/icons/get_donates.svg';
import { ReactComponent as GetStar } from 'shared/assets/icons/get_star.svg';
import { AppleDownloadApp, Button, GoogleDownloadApp } from 'shared/ui';
import styles from './styles.module.scss';

export function DownloadAppPopup(): JSX.Element {
  const { t } = useTranslate();
  const Trans = useTrans();
  const [isShowModal, setIsShowModal] = useState(false);

  const toggleIsShowModal = (): void => {
    setIsShowModal((prevState) => !prevState);
  };

  const handleModalClose = (): void => {
    document.body.style.overflowY = 'auto';
    toggleIsShowModal();
  };

  const renderModal = (): ReactPortal | null => {
    if (!isShowModal) return null;

    return createPortal(
      <>
        <div onClick={handleModalClose} className={styles.modalContainer} />
        <div className={styles.modal}>
          <p className={styles.modal_text}>{t('download_app_and_get_donates')}</p>
          <div className={styles.modal_icons_container}>
            <AppleDownloadApp />
            <GoogleDownloadApp />
          </div>
          <Button onClick={handleModalClose} className={styles.modal_btn} title={t('okey')} />
        </div>
      </>,
      document.body,
    );
  };

  const handleModalOpen = (): void => {
    document.body.style.overflowY = 'hidden';
    toggleIsShowModal();
  };

  return (
    <div className={styles.container}>
      <img className={styles.container_avatar} src={WinnerAvatar} alt='Winner Avatar' />
      <div className={styles.container_text_container}>
        <div className={styles.container_text}>
          <DownloadAppIcon />
          <Trans i18nKey='download_app_popup'>
            <p>
              <b
                id='download-app'
                onClick={handleModalOpen}
                className={styles.container_text_download}
              >
                Скачай приложение
              </b>
              получи <strong>100 донатов</strong> и доступ в премиум ленту
            </p>
          </Trans>
        </div>
        <div className={styles.container_text}>
          <GetDonatesIcon />
          <p>{t('get_donates_popup')}</p>
        </div>
        <div className={styles.container_text}>
          <GetStar />
          <p>{t('get_star_popup')}</p>
        </div>
      </div>
      {renderModal()}
    </div>
  );
}
