import { Partner as IPartner } from 'shared/models';
import styles from './styles.module.scss';

type PartnerProps = Omit<IPartner, 'id' | 'order'>;

export function Partner({ title, image, link }: PartnerProps): JSX.Element {
  return (
    <a href={link} target='_blank' rel='noopener noreferrer'>
      <img src={image} className={styles.partners_partner} alt={title} loading='lazy' />
      <p className={styles.partners_partner_title}>{title}</p>
    </a>
  );
}
