import { Button, Spinner } from 'shared/ui';
import { useTranslate } from 'shared/hooks';
import styles from './styles.module.scss';

interface ShowMorePartnersProps {
  increaseOffset: () => void;
  isLoading: boolean | null;
}

export function ShowMorePartners({
  increaseOffset,
  isLoading,
}: ShowMorePartnersProps): JSX.Element {
  const { t } = useTranslate();

  if (isLoading) return <Spinner className={styles.spinner} />;

  return <Button onClick={increaseOffset} className={styles.show_more} title={t('more')} />;
}
