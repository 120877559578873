import { useCarousel } from 'nuka-carousel';
import styles from './styles.module.scss';

export function CustomDots(): JSX.Element {
  const { totalPages, currentPage, goToPage } = useCarousel();

  const changePage = (i: number) => () => {
    goToPage(i);
  };

  return (
    <div className={styles.customDots}>
      {[...Array(totalPages)].map((_, i) => {
        const key = `dot-${i}`;
        return (
          <button
            id={key}
            className={`${styles.customDots_dot} ${
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              currentPage === i ? styles.customDots_active_dot : undefined
            }`}
            onClick={changePage(i)}
            key={key}
          />
        );
      })}
    </div>
  );
}
