import { Partner } from '../models';
import { publicApi } from './api';

interface RequirePartners {
  offset?: number;
  limit?: number;
}

interface RequirePartnersResult {
  results: Partner[];
  next: string | null;
}

export async function requirePartners({
  offset,
  limit,
}: RequirePartners): Promise<RequirePartnersResult> {
  const res = await publicApi.get<RequirePartnersResult>('/api/partners', {
    params: {
      offset,
      limit,
    },
  });
  return res.data;
}
