import { useTranslate } from '../../hooks';
import { NashDownload } from '../icons';
import styles from './styles.module.scss';

export function NashstoreDownloadApp(): JSX.Element | null {
  const { language } = useTranslate();

  if (language !== 'ru') return null;

  return (
    <a
      className={styles.nashStore}
      href='https://store.nashstore.ru/store/62b1e249fb3ed3efa0ec78fe'
      target='_blank'
      referrerPolicy='no-referrer'
      rel='noreferrer'
    >
      <NashDownload />
    </a>
  );
}
